import { mount } from '@vue/test-utils'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    image: {
      type: Object,
      required: true,
    },

    content: {
      type: String,
      required: true,
    },

    button: {
      type: Object,
      required: false,
    },
  },

  components: {
    hotProgressBar: () => import('~/components/Molecules/ProgressBar/ProgressBar.vue'),
  },

  data() {
    return {
      countTicks: 0,
      maxTicks: 100,
    }
  },
}
