var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"organic-formats"},[_c('div',{staticClass:"container-organic"},[_c('div',{class:[
        'organic-formats__container',
        { 'organic-formats__container--desktop': _vm.isDesktop },
        { 'organic-formats__container--mobile': !_vm.isDesktop },
      ]},[_c('div',{class:[`organic-formats__menu`, { 'with--checklist': _vm.hasChecklist }]},[_c('div',{staticClass:"organic-formats__title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.subtitle)}}),_vm._v(" "),_c('hot-title',{attrs:{"tag":"h2"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.title)}})])],1),_vm._v(" "),_c('div',{staticClass:"organic-formats__options"},_vm._l((_vm.list),function(option,i){return _c('div',_vm._b({key:i,staticClass:"organic-formats__option",on:{"click":function($event){$event.preventDefault();return _vm.selectOption(i)}}},'div',option.dataAttributes,false),[(_vm.isDesktop || (!_vm.isDesktop && i === _vm.index))?_c('div',{class:['organic-formats__select', { 'organic-formats__select--selected': i === _vm.index }]},[_c('div',{attrs:{"id":`item-formatos-${option.title.toLowerCase()}`},domProps:{"innerHTML":_vm._s(option.title)}})]):_vm._e(),_vm._v(" "),(i === _vm.index)?_c('hot-progress-bar',{attrs:{"currentStep":_vm.countTicks,"steps":_vm.maxTicks,"label":`item-formatos-${option.title.toLowerCase()}`}}):_vm._e(),_vm._v(" "),(option.checklist)?_c('ul',{class:['organic-formats__checklist', { 'organic-formats__checklist--selected': i === _vm.index }]},_vm._l((option.checklist),function(item,j){return _c('li',{key:j},[_c('hot-icon',{attrs:{"name":"check"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(item)}})],1)}),0):_vm._e()],1)}),0)]),_vm._v(" "),_c('div',{class:['organic-formats__content', { isTransitioning: _vm.isTransitioning }]},[_c('organic-format-content',_vm._b({attrs:{"isTransitioning":_vm.isTransitioning,"isDesktop":_vm.isDesktop}},'organic-format-content',_vm.list[_vm.index],false))],1)]),_vm._v(" "),_c('div',{staticClass:"organic-formats__slider"},[_c('div',{staticClass:"organic-formats__cards"},[_c('hot-carousel',{attrs:{"organic":true,"component":{
            is: _vm.OrganicFormatContentSlider,
            name: 'organicFormatContentSlider',
            items: _vm.list,
          },"swiper-options":_vm.swiperOptions}})],1)]),_vm._v(" "),(_vm.hasCta)?_c('div',{staticClass:"organic-formats__cta"},[_c('hot-organic-button',_vm._b({attrs:{"href":"javascript:void(0);"},on:{"click":function($event){$event.preventDefault();return _vm.openModal.apply(null, arguments)}}},'hot-organic-button',_vm.cta,false),[_vm._v("\n        "+_vm._s(_vm.cta.label)+"\n      ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }