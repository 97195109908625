import OrganicFormatContent from '~/components/Molecules/Organic/FormatContent/FormatContent.vue'
import OrganicFormatContentSlider from '~/components/Molecules/Organic/FormatContentSlider/FormatContentSlider.vue'
import modalSignup from '~/mixins/modalSignup.js'
import device from '~/mixins/device.js'

export default {
  mixins: [modalSignup, device],

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },

    hasChecklist: {
      type: Boolean,
      required: false,
      default: false,
    },

    link: {
      type: Object,
    },

    duration: {
      type: Number,
      required: false,
      default: 400,
    },

    list: {
      type: Array,
    },

    cta: {
      type: Object,
    },
  },

  components: {
    OrganicFormatContent,
    OrganicFormatContentSlider,
    hotProgressBar: () => import('~/components/Molecules/ProgressBar/ProgressBar.vue'),
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      OrganicFormatContentSlider: OrganicFormatContentSlider,
      swiperOptions: {
        spaceBetween: 36,
        slidesPerView: 1,
        speed: 1000,
        allowTouchMove: true,
        normalizeSlideIndex: false,
        centeredSlides: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.2,
            spaceBetween: 16,
          },
        },

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          el: '.swiper-navigation',
          clickable: true,
        },
      },
      index: 0,
      countTicks: 0,
      maxTicks: this.duration,
      intervalID: null,
      isTransitioning: false,
      canChange: true,
    }
  },

  mounted() {
    if (this.isDesktop) {
      this.animate()
    }
  },

  beforeDestroy() {
    cancelAnimationFrame(this.intervalID)
  },

  computed: {
    lastOption() {
      return Boolean(this.index == this.list.length - 1)
    },

    firstOption() {
      return Boolean(this.index == 0)
    },

    hasCta() {
      return Boolean(this.cta)
    },
  },

  methods: {
    animate() {
      const animateLoop = () => {
        this.updateProgress()
        this.intervalID = requestAnimationFrame(animateLoop)
      }
      animateLoop()
    },

    resetOption() {
      this.countTicks = 0
      this.isTransitioning = true
      this.canChange = false
    },

    async updateProgress() {
      if (this.countTicks === this.maxTicks) {
        this.resetOption()

        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 500)
        })

        this.index = this.lastOption ? 0 : (this.index + 1) % this.list.length
        this.isTransitioning = false
      }

      this.countTicks++
    },

    async selectOption(i) {
      this.resetOption()

      if (this.firstOption && i < 0) {
        i = this.list.length - 1
      } else if (this.lastOption && i > this.list.length - 1) {
        i = 0
      }

      await new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, 500)
      })

      this.index = i
      this.isTransitioning = false
    },
  },
}
